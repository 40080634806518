





































import {Component, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, TribeLogDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';

Vue.use(VueAsyncComputed);

@Component({
    components: {}
})
export default class TribeLogs extends Vue {

    private loading = true;
    private initialized = false;
    private selectedServers: number[] = [];
    private selectedTribes: number[] = [];

    private headers() {
        const columns = [];
        columns.push({text: 'Server', value: 'serverId'});
        columns.push({text: 'Day', value: 'day'});
        columns.push({text: 'Time', value: 'time'});
        columns.push({text: 'Archived', value: 'created'});
        columns.push({text: 'Message', value: 'message'});
        return columns;
    }

    public created(): void {
        const queryParams = this.$route.query;

        this.selectedServers = queryParams.server
            ? Array.isArray(queryParams.server) ? queryParams.server.map(s => Number(s)) : [Number(queryParams.server)]
            : [];

        this.selectedTribes = queryParams.tribe
            ? Array.isArray(queryParams.tribe) ? queryParams.tribe.map(s => Number(s)) : [Number(queryParams.tribe)]
            : [];

        this.initialized = true;
    }

    @AsyncComputedProp()
    public async tribeLogs(): Promise<TribeLogDto[] | null> {
        if (!this.initialized) {
            return [];
        }
        this.loading = true;
        const structures = await ArkStatsBackendClient.getTribeLogs({
            orderBy: '-day,-time',
            fields: ['serverId', 'tribeId', 'day', 'time', 'message', 'created'],
            filter: this.filter({})
        });
        this.loading = false;
        return structures.data;
    }

    get url(): string {
        const queryParams: string[] = [];
        if (this.selectedServers && this.selectedServers.length) {
            queryParams.push(this.selectedServers.map(t => 'server=' + t).join('&'));
        }
        if (this.selectedTribes && this.selectedTribes.length) {
            queryParams.push(this.selectedTribes.map(t => 'tribe=' + t).join('&'));
        }
        const url = this.$route.path + '?' + queryParams.join('&');
        history.pushState({}, 'Title', url);
        return url;
    }

    escape(value: string): string {
        if (value.replaceAll) {
            return value
                .replaceAll(`\\`, `\\\\`)
                .replaceAll(`'`, `\\'`)
                .replaceAll(`"`, `\\"`);
        } else {
            return value;
        }
    }

    public filter({server = true, tribe = true}): string {
        const filters: string[] = [];

        /*
        if (type && this.selectedTypes?.length) {
            filters.push('type=in=("' + this.selectedTypes.map(this.escape).join('","') + '")');
        }*/
        if (tribe && this.selectedTribes?.length) {
            filters.push('tribeId=in=("' + this.selectedTribes.join('","') + '")');
        }
        if (server && this.selectedServers?.length) {
            filters.push('serverId=in=(' + this.selectedServers.join(',') + ')');
        }
        /*
        if (count && this.selectedMinCount) {
            filters.push('count=ge=' + this.selectedMinCount);
        }
        if (lastInAllyRange && this.lastInAllyRangeBefore) {
            filters.push('lastInAllyRange=le=' + moment(this.lastInAllyRangeBefore).toISOString());
        }*/
        return filters.join(';');
    }

    private addSelectedValues<T>(values: T[], selected: T[]): T[] {
        if (!selected || !selected.length) {
            return values;
        }
        selected.forEach(type => {
            if (values.indexOf(type) === -1) {
                values.push(type);
            }
        });
        if (typeof values[0] === 'number') {
            values.sort((a, b) => Number(a) - Number(b));
        } else {
            values.sort();
        }
        return values;
    }

    @AsyncComputedProp()
    public async servers(): Promise<number[]> {
        if (!this.initialized) {
            return [];
        }
        return this.addSelectedValues(
            await ArkStatsBackendClient.getTribeLogValues(
                'serverId',
                this.filter({server: false})),
            this.selectedServers);
    }

    @AsyncComputedProp()
    public async tribes(): Promise<number[]> {
        if (!this.initialized) {
            return [];
        }
        return this.addSelectedValues(
            await ArkStatsBackendClient.getTribeLogValues(
                'tribeId',
                this.filter({tribe: false})),
            this.selectedTribes);
    }
}
